import React from 'react';
import { motion } from 'framer-motion';

const Nivel = ({ comp, exp, size }) => {
  return (
    <div className="overflow-x-hidden">
      <p className="text-sm uppercase font-medium">{comp}</p>
      <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-2">
        <motion.span
          initial={{ x: '-100%', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className={`w-${size} h-full bg-gradient-to-r from-red-500 to-green-500 rounded-md relative`}
        >
          <span className="absolute -top-7 right-0">{exp}</span>
        </motion.span>
      </span>
    </div>
  );
};

export default Nivel;
