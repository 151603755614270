import React from 'react';
import { motion } from 'framer-motion';
import Nivel from './Nivel';

const Skills = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px] uppercase">
            Características
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">
            Desenvolvedor Frontend
          </h2>
        </div>
        <div className='className="mt-14 w-full flex flex-col gap-6'>
          <Nivel comp="HTML" exp="Experiente" size="full" />
          <Nivel comp="CSS" exp="Experiente" size="full" />
          <Nivel comp="JAVASCRIPT" exp="Avançado" size="[80%]" />
          <Nivel comp="REACT" exp="Avançado" size="[80%]" />
          <Nivel comp="TAILWIND" exp="Avançado" size="[70%]" />
        </div>
      </div>

      <div className="w-full lgl:w-1/2">
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px] uppercase">
            Características
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">
            Desenvolvedor Backend
          </h2>
        </div>
        <div className="flex flex-col gap-6">
          <Nivel comp="C#" exp="Intermediário" size="[60%]" />
          <Nivel comp="SQL" exp="Avançado" size="[80%]" />
          <Nivel comp="NODE.JS" exp="Avançado" size="[70%]" />
          <Nivel comp="API REST" exp="Avançado" size="[70%]" />
        </div>
      </div>
    </motion.div>
  );
};

export default Skills;
