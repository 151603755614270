import React, { useState } from 'react';
import Titulo from '../layout/Titulo';
import Slider from 'react-slick';
import GreenVille from '../../assets/images/testmonial/egv.png';
import LaisCarrera from '../../assets/images/testmonial/LaisCarrera.png';
import Bruno from '../../assets/images/testmonial/Bruno.png';
import Slide from './Slide';

const Testemunho = () => {
  const [dotActive, setDocActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },
    appendDots: (dots) => (
      <div
        style={{
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        <ul
          style={{
            display: 'flex',
            gap: '15px',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          {' '}
          {dots}{' '}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={
          i === dotActive
            ? {
                width: '12px',
                height: '12px',
                color: 'green',
                background: '#04ff00',
                borderRadius: '50%',
                cursor: 'pointer',
              }
            : {
                width: '12px',
                height: '12px',
                color: 'blue',
                background: 'gray',
                borderRadius: '50%',
                cursor: 'pointer',
              }
        }
      ></div>
    ),
  };

  return (
    <section
      id="testemunho"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Titulo title={'O que os clientes dizem'} desc={'Feedbacks'} />
      </div>

      <div className="max-w-6xl mx-auto">
        <Slider {...settings}>
          {/* SLIDE 1 */}
          <Slide
            src={Bruno}
            alt="Casal Operante"
            empresa="Casal Operante"
            resp="Bruno Carlos"
            cargo="Psicólogo"
            service="Criação de site"
            subTitle="Ótimo profissional"
            desc="O site realizado pelo Pablo, me possibilitou ter a minha casa na internet. Agora, independente do que acontecer com as demais redes, eu terei meu espaço ali. O site ficou exatamente como eu pedi, simples e de fácil acesso e entendimento pelos meus pacientes. Até agora, não tive nenhum tipo de problema com o funcionamento do site. Como não entendo nada, entreguei nas mãos do Pablo e ele me entregou pronto, já com domínio. Sensacional!"
          />

          {/* SLIDE 2 */}
          <Slide
            src={LaisCarrera}
            alt="Lais Carrera"
            empresa="Cerimonialista Lais Carrera"
            resp="Lais Carrera"
            cargo="Cerimonialista"
            service="Criação de site e SEO"
            subTitle="Profissional responsável e ágil"
            desc="Gostaria de parabenizá-lo pelo excelente trabalho realizado na criação do site do meu serviço e para os sites criados para meus Clientes, meus noivinhos adoram ver que eles tem um site. Foi uma experiência muito positiva trabalhar com você. Obrigado pelo excelente trabalho e espero poder contar com você novamente no futuro."
          />

          {/* SLIDE 3 */}
          <Slide
            src={GreenVille}
            alt="Espaço Green Ville"
            empresa="Espaço Green Ville"
            resp="Milton Carrera"
            cargo="Gerente"
            service="Sistema de evento e criação de site"
            subTitle="Profissional muito bom"
            desc="Eu gostaria de compartilhar meu feedback sobre o trabalho realizado na criação do site e Sistema de Enevtos para nosso salão. Fiquei extremamente satisfeito com todo o processo e com o resultado final. A criação foi um sucesso desde o início. O design e a funcionalidade atenderam perfeitamente às necessidades. Um site de fácil navegação e com todas as informações relevantes sobre o salão de festas."
          />
        </Slider>
      </div>
    </section>
  );
};

export default Testemunho;
