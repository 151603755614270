import { React, useEffect } from 'react';
import Titulo from '../layout/Titulo';
import NavbarInterno from '../navbar/NavbarInterno';
import FooterInterno from '../footer/FooterInterno';
import Copy from '../footer/Copy';

import ReactGA from 'react-ga';

const Tecnologias = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <NavbarInterno />
      <section
        id="tecnologias"
        className="w-full h-[90vh] py-10 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Titulo title="Saiba o que usei" desc="Tecnologias" />
        </div>

        <div className="flex flex-col gap-6 xl:gap-14 pb-8 items-center">
          <ul className="text-2xl lg:text-lg list-disc flex flex-col gap-2">
            <li className="text-white font-bold hover:text-designColor">
              React
            </li>
            <li className="ml-6 hover:text-designColor">react-router-dom</li>
            <li className="ml-6 hover:text-designColor">react-scroll</li>
            <li className="ml-6 hover:text-designColor">react-slick</li>
            <li className="ml-6 hover:text-designColor">react-icons</li>
            <li className="ml-6 hover:text-designColor">
              react-simple-typewriter
            </li>
            <li className="ml-6 hover:text-designColor">react-input-mask</li>
            <li className="ml-6 hover:text-designColor">framer-motion</li>

            <li className="text-white font-bold hover:text-designColor">
              HTML
            </li>
            <li className="text-white font-bold hover:text-designColor">CSS</li>
            <li className="text-white font-bold hover:text-designColor">
              Tailwind CSS
            </li>
          </ul>
        </div>
      </section>
      <FooterInterno />
      <Copy />
    </div>
  );
};

export default Tecnologias;
