import { React, useEffect } from 'react';
import Titulo from '../layout/Titulo';
import NavbarInterno from '../navbar/NavbarInterno';
import FooterInterno from '../footer/FooterInterno';
import Copy from '../footer/Copy';

import ReactGA from 'react-ga';

const Documentacao = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <NavbarInterno />
      <section
        id="termoSeguranca"
        className="w-full h-full py-10 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Titulo title="Documentação" />
        </div>

        <div className="flex flex-col gap-4 pb-8 text-md tracking-wide">
          <h2 className="text-3xl text-white text-center">Visão Geral</h2>
          <p>
            O Pablosantos.dev é um site pessoal de propriedade e manutenção
            minha. É um site de portfólio que mostra suas habilidades,
            experiência e projetos no campo do desenvolvimento web. O site foi
            projetado com uma aparência limpa e moderna e é fácil de navegar.
          </p>
          <h2 className="text-2xl text-white">Recursos</h2>
          <p>O site contém os seguintes recursos:</p>
          <p>
            <span className="text-lg text-designColor">Página Inicial:</span> A
            página inicial exibe uma mensagem de boas-vindas e uma breve
            introdução a Pablo Santos. Também contém links para seus perfis de
            mídia social, um formulário de contato e uma lista de suas
            habilidades.
          </p>
          <p>
            <span className="text-lg text-designColor">Página Sobre:</span> A
            página sobre fornece uma descrição detalhada sobre mim, minhas
            informações sobre ensino, experiência, idiomas estrangeiros e
            habilidades.
          </p>
          <p>
            <span className="text-lg text-designColor">Página Projetos:</span> A
            página de projetos contém uma lista de projetos em que criei. Cada
            projeto é exibido com uma captura de tela, uma breve descrição e um
            link para o site do projeto.
          </p>
          <p>
            <span className="text-lg text-designColor">
              Página do Feedbacks:
            </span>
            A página do Feedbacks contém uma lista de postagens de Feedbacks
            escritos por clientes. Cada postagem é exibida com um título, uma
            imagem do cliente ou empresa e um feedback completo.
          </p>
          <p>
            <span className="text-lg text-designColor">Página de Contato:</span>{' '}
            A página de contato fornece um formulário de contato que os
            visitantes podem usar para enviar mensagens para mim. Ele também
            exibe seu endereço de e-mail e links para meus perfis de mídia
            social.
          </p>
          <h2 className="text-2xl text-white">Design</h2>
          <p>
            O Pablosantos.dev é projetado com uma aparência minimalista e
            moderna. A paleta de cores é principalmente preto e verde. O site é
            totalmente responsivo e funciona bem em todos os dispositivos,
            incluindo desktops, laptops, tablets e smartphones.
          </p>
          <h2 className="text-2xl text-white">Tecnologia</h2>
          <p>O site é construído usando as seguintes tecnologias:</p>
          <p>
            <span className="text-lg text-designColor">React:</span> O site é
            construído usando React, a versão mais recente da linguagem, tanto
            na parte de design quanto na parte de interações e animações.
          </p>
          <p>
            <span className="text-lg text-designColor">CSS3:</span> O site é
            estilizado usando CSS3, a versão mais recente da linguagem de folha
            de estilo CSS.
          </p>
          <p>
            <span className="text-lg text-designColor">Tailwind CSS:</span> O
            site é construído usando o Tailwind, um framework front-end popular
            para construir sites responsivos.
          </p>

          <h2 className="text-2xl text-white">Implantação</h2>
          <p>
            O site está hospedado na Hostgator, um serviço de hospedagem pago e
            o domínio comprado no Google Domains. O código-fonte do site é
            armazenado em um repositório do GitHub, de forma privada e no
            netlify, de forma pública.
          </p>
          <h2 className="text-2xl text-white">Conclusão</h2>
          <p>
            O Pablosantos.dev é um site bem projetado e informativo que
            apresenta as habilidades e projetos de Pablo Santos. É construído
            usando as últimas tecnologias web e é totalmente responsivo,
            tornando-o fácil de visualizar em todos os dispositivos. Se você
            tiver alguma dúvida ou feedback sobre o site, sinta-se à vontade
            para usar o formulário de contato para entrar em contato comigo.
          </p>
        </div>
      </section>
      <FooterInterno />
      <Copy />
    </div>
  );
};

export default Documentacao;
