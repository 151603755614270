import React from 'react';
import Banner from '../banner/Banner';
import Projetos from '../projetos/Projetos';
import Curriculo from '../curriculo/Curriculo';
import Sobre from '../sobre/Sobre';
import Testemunho from '../testemunho/Testemunho';
import Contato from '../contato/Contato';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Copy from '../footer/Copy';

const Home = () => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <Navbar />
      <Banner />
      <Sobre />
      <Curriculo />
      <Projetos />
      <Testemunho />
      <Contato />
      <Footer />
      <Copy />
    </div>
  );
};

export default Home;
