import React, { useState } from 'react';
import Botao from '../layout/Botao';
import Titulo from '../layout/Titulo';
import Conquistas from './Conquistas';
import Educacao from './Educacao';
import Experiencia from './Experiencia';
import Skills from './Skills';
import arquivo from '../../assets/curriculo/Pablo_Santos.pdf';

const Curriculo = () => {
  const [educacao, setEducacao] = useState(true);
  const [skills, setSkills] = useState(false);
  const [experiencia, setExperiencia] = useState(false);
  const [conquista, setConquista] = useState(false);

  return (
    <section
      id="curriculo"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Titulo title="Conheça minha trajetória" desc="Currículo" />
      </div>
      <div>
        <ul className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <li
            onClick={() =>
              setEducacao(true) &
              setSkills(false) &
              setExperiencia(false) &
              setConquista(false)
            }
            className={`${
              educacao ? 'border-designColor rounded-lg' : 'border-transparent'
            } resumoLi`}
          >
            Educação
          </li>

          <li
            onClick={() =>
              setEducacao(false) &
              setSkills(true) &
              setExperiencia(false) &
              setConquista(false)
            }
            className={`${
              skills ? 'border-designColor rounded-lg' : 'border-transparent'
            } resumoLi`}
          >
            Skills
          </li>

          <li
            onClick={() =>
              setEducacao(false) &
              setSkills(false) &
              setExperiencia(true) &
              setConquista(false)
            }
            className={`${
              experiencia
                ? 'border-designColor rounded-lg'
                : 'border-transparent'
            } resumoLi`}
          >
            Experiências
          </li>

          <li
            onClick={() =>
              setEducacao(false) &
              setSkills(false) &
              setExperiencia(false) &
              setConquista(true)
            }
            className={`${
              conquista ? 'border-designColor rounded-lg' : 'border-transparent'
            } resumoLi`}
          >
            Idiomas estrangeiros
          </li>
        </ul>
      </div>
      {educacao && <Educacao />}
      {skills && <Skills />}
      {experiencia && <Experiencia />}
      {conquista && <Conquistas />}

      <Botao ender={arquivo} title="Currículo PDF" />
    </section>
  );
};

export default Curriculo;
