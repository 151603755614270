import { React, useEffect } from 'react';
import Titulo from '../layout/Titulo';
import NavbarInterno from '../navbar/NavbarInterno';
import FooterInterno from '../footer/FooterInterno';
import Copy from '../footer/Copy';

import ReactGA from 'react-ga';

const Services = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <NavbarInterno />
      <section
        id="services"
        className="w-full h-[90vh] py-10 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Titulo title="Saiba o que usei" desc="Serviços" />
        </div>

        <div className="flex flex-col gap-6 xl:gap-14 pb-8 items-center">
          <ul className="text-2xl lg:text-lg list-disc flex flex-col gap-2">
            <li className="text-white font-bold hover:text-designColor">
              Hospedagem
            </li>
            <li className="ml-6 hover:text-designColor">Hostgator</li>
            <li className="text-white font-bold hover:text-designColor">
              Dominio .dev
            </li>
            <li className="ml-6 hover:text-designColor">Google Domains</li>
            <li className="text-white font-bold hover:text-designColor">
              Hospedagem React
            </li>
            <li className="ml-6 hover:text-designColor">netlify</li>
            <li className="text-white font-bold hover:text-designColor">
              Hospedagem do codigo aberto
            </li>
            <li className="ml-6 hover:text-designColor">gitHub</li>
          </ul>
        </div>
      </section>
      <FooterInterno />
      <Copy />
    </div>
  );
};

export default Services;
