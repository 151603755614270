import React from 'react';
import MeEncontre from '../layout/MeEncontre';

const CardContato = ({ src, alt, title, cargo, desc, cel, email }) => {
  return (
    <div className="w-full lg:w-[35%] h-auto bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne p-4 lg:p-8 rounded-lg flex flex-col gap-8 justify-center">
      <img
        className="h-64 w-full rounded-lg object-cover"
        src={src}
        alt={alt}
      />

      <div className="flex flex-col gap-4">
        <h3 className="text-3xl font-bold text-gray-200">{title}</h3>
        <p className="text-lg text-gray-400 tracking-wide">{cargo}</p>

        <p className="text-base -tracking-wide text-gray-400">{desc}</p>
        <p className="text-base -tracking-wide text-gray-400">
          Contato:{' '}
          <a href="tel:+5521970139730">
            <samp className="text-base -tracking-wide text-gray-300 hover:text-designColor">
              {cel}
            </samp>
          </a>
        </p>
        <p className="text-base -tracking-wide text-gray-400">
          Email:{' '}
          <a href="mailto:pablovieira.san@gmail.com">
            <samp className="text-base -tracking-wide text-gray-300 hover:text-designColor">
              {email}
            </samp>
          </a>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-base uppercase font-titleFont mb-4">
          Me encontre em
        </h2>
        <MeEncontre />
      </div>
    </div>
  );
};

export default CardContato;
