import React from 'react';
import { motion } from 'framer-motion';
import CardResumo from './CardResumo';

const Educacao = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.8 } }}
      className="w-full flex flex-col md:flex-row gap-20"
    >
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2023</p>
          <h2 className="text-3xl md:text-4xl font-bold">Formação</h2>
        </div>
        <div className="w-full mt-14 h-[1000px] border-l-[6px] border-black border-opacity-30 flex flex-col gap-10">
          <CardResumo
            title="Engenharia de Software"
            subTitle="Centro Universitário A. P. AMPLI ( 2024* )"
            desc="A graduação da universidade, tem como objetivo me preparar para atuar em diversos setores do projeto ou áreas da programação, utilizando metodologias ágeis e boas práticas."
          />
          <CardResumo
            title="Ensino Médio Técinico em Informática"
            subTitle="Escola Brasil-Croácia"
            desc="Ensino médio, que teve como objetivo me preparar para o ensino superior e introduzir-me ao mundo dos computadores com aulas basicas de front-end e manutenção."
          />
          <CardResumo
            title="Montagem e Manutenção de Micros"
            subTitle="JFW"
            desc="Curso que teve como objetivo ensinar-me sobre montagem e manutenção de microcomputadores, introdução a redes e o basico de photoshop."
          />
        </div>
      </div>

      {/* Parte 2 */}
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2023</p>
          <h2 className="text-3xl md:text-4xl font-bold">Cursos</h2>
        </div>
        <div className="w-full mt-14 h-[1000px] border-l-[6px] border-black border-opacity-30 flex flex-col gap-10">
          <CardResumo
            title="HTML, CSS e JavaScript ES6+"
            subTitle="Origamid"
            desc="O curso que teve como objetivo ensinar tudo sobre desenvolvimento front-end, boas práticas de criação de páginas, Grid, Flexbox, frameworks e muito mais."
          />
          <CardResumo
            title="Carreira Desenvolvedor Backend .NET"
            subTitle="Balta.io"
            desc="O curso que teve como objetivo ensinar sobre a criação de aplicações utilizando .NET, C#, Entity Framework, criação de API, dos fundamentos a arquitetura de software"
          />
          <CardResumo
            title="React"
            subTitle="Origamid"
            desc="O curso que teve como objetivo ensinar a como utilizar o React do zero para o desenvolvimento de aplicações web reativas, com componentização, Status, APIs e muito mais."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Educacao;
