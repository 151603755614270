import React from 'react';
import { GrSystem } from 'react-icons/gr';
import { CgWebsite } from 'react-icons/cg';

import { FaMobile, FaGlobe } from 'react-icons/fa';
import { SiProgress, SiAntdesign } from 'react-icons/si';
import Titulo from '../layout/Titulo';
import Card from './Card';

const Sobre = () => {
  return (
    <section
      id="servicos"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Titulo title={'Conhecimentos'} desc={'O que eu faço?'} />

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="Criação de sites"
          desc="Conquiste clientes na web! Tenha um site atraente e eficiente. Design personalizado e conteúdo relevante."
          icon={<CgWebsite />}
        />
        <Card
          title="Criação de sistemas"
          desc="Aumente sua eficiência! Tenha um sistema web personalizado para sua empresa. Ganhe tempo e qualidade."
          icon={<GrSystem />}
        />
        <Card
          title="Otimização de SEO"
          desc="Apareça no topo das pesquisas! Aumente suas vendas com nossa otimização de SEO. Resultados garantidos."
          icon={<SiProgress />}
        />
        <Card
          title="Sites responsivos"
          desc="Esteja presente em todas as plataformas! Tenha um site responsivo. Aumente seu alcance e conquiste mais clientes."
          icon={<FaMobile />}
        />
        <Card
          title="Identidade Visual"
          desc="Nos diga o que pensa que criaremos sua identidade visual completa, desde logos a artes para sua camisa e cartão de visita."
          icon={<SiAntdesign />}
        />
        <Card
          title="Hospedagem de sites"
          desc="Tenha um site rápido e seguro! Hospede seu site com nossa tecnologia de ponta. 99,9% de uptime garantido."
          icon={<FaGlobe />}
        />
      </div>
    </section>
  );
};

export default Sobre;
