import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import emailJs from '@emailjs/browser';

const ContatoRight = () => {
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  // ========== Validação de email com regex e transformando todas letras em minusculas ==============
  const emailValidation = () => {
    return String(email)
      .toLocaleLowerCase()
      .match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      );
  };
  // ========== Validação de email com regex ================

  const handleSend = (e) => {
    e.preventDefault();
    if (username === '') {
      setErrMsg('Por favor, digite seu nome!');
    } else if (phoneNumber === '') {
      setErrMsg('Por favor, Insita o telefone de contato!');
    } else if (email === '') {
      setErrMsg('Por favor, coloque seu Email!');
    } else if (!emailValidation(email)) {
      setErrMsg('Coloque um email válido!');
    } else if (subject === '') {
      setErrMsg('Por favor, digite o assunto!');
    } else if (message === '') {
      setErrMsg('Por favor, escreva uma mensagem!');
    } else {
      const templateParams = {
        subject: subject,
        from_name: username,
        email: email,
        contact: phoneNumber,
        message: message,
      };

      emailJs.send(
        'service_9w2ziff',
        'template_pwbybme',
        templateParams,
        'V04serOHYXbC11gJ0',
      );
      setSuccessMsg(
        `Obrigado pelo contato ${username}, Sua mensagem foi emviada com Sucesso!`,
      );
      setErrMsg('');
      setUsername('');
      setPhoneNumber('');
      setEmail('');
      setSubject('');
      setMessage('');
    }
  };

  return (
    <div className="w-full lg:w-[60%] h-full flex flex-col justify-between">
      <div className="w-full h-[70%] bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne rounded-lg flex flex-col p-4 lg:p-8 justify-center gap-8">
        <form className="w-full flex flex-col gap-4 lg:gap-6 py-2 lg:p-5">
          {errMsg && (
            <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
              {errMsg}
            </p>
          )}
          {successMsg && (
            <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
              {successMsg}
            </p>
          )}
          <div className="w-full flex flex-col lg:flex-row gap-10">
            <div className="w-full lg:w-1/2 flex flex-col gap-4">
              <p className="text-sm text-gray-400 uppercase tracking-wide">
                Digite seu nome
              </p>
              <input
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                className={`${
                  errMsg === 'Por favor, digite seu nome!' &&
                  'outline-designColor'
                } contatoInput`}
                type="text"
              />
            </div>
            <div className="w-full lg:w-1/2 flex flex-col gap-4">
              <p className="text-sm text-gray-400 uppercase tracking-wide">
                Digite seu Contato
              </p>
              <InputMask
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                className={`${
                  errMsg === 'Por favor, Insita o telefone de contato!' &&
                  'outline-designColor'
                } contatoInput`}
                mask="(99)9999-99999"
                maskChar=" "
                type="tel"
              />
            </div>
          </div>
          <div className="flex flex-col gap-6">
            <p className="text-sm text-gray-400 uppercase tracking-wide">
              Digite seu Email
            </p>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className={`${
                errMsg === 'Por favor, coloque seu Email!' &&
                'outline-designColor'
              } contatoInput`}
              type="email"
            />
          </div>
          <div className="flex flex-col gap-6">
            <p className="text-sm text-gray-400 uppercase tracking-wide">
              Digite o Assunto
            </p>
            <input
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              className={`${
                errMsg === 'Por favor, digite o assunto!' &&
                'outline-designColor'
              } contatoInput`}
              type="text"
            />
          </div>
          <div className="flex flex-col gap-6">
            <p className="text-sm text-gray-400 uppercase tracking-wide">
              Por favor, Digite sua mensagem
            </p>
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              className={`${
                errMsg === 'Por favor, escreva uma mensagem!' &&
                'outline-designColor'
              } contatotTextArea`}
              cols="30"
              rows="8"
            ></textarea>
          </div>
          <div className="w-full">
            <button
              onClick={handleSend}
              className="w-full h-12 bg-[#141518] rounded-lg text-base text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-designColor border-transparent"
            >
              Enviar Mensagem
            </button>
          </div>
          {errMsg && (
            <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
              {errMsg}
            </p>
          )}
          {successMsg && (
            <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
              {successMsg}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContatoRight;
