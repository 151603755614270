import React from 'react';
import { NavLink } from 'react-router-dom';
import MeEncontre from '../layout/MeEncontre';
import arquivo from '../../assets/curriculo/Pablo_Santos.pdf';
import logo from '../../assets/images/website/logo.png';

const FooterInterno = () => {
  return (
    <div className="w-full h-auto py-20 border-b-[1px] border-b-black grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      <div className="w-full h-full flex flex-col gap-8 ">
        <img src={logo} alt="Logo" width={70} height={50} />
        <MeEncontre />
      </div>
      <div className="w-full h-full flex flex-col">
        <h3 className="text-xl uppercase text-designColor tracking-wide">
          Links Importantes
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <NavLink
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              to="/portifolio"
            >
              Projetos
            </NavLink>
          </li>
          <li>
            <a
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              href={arquivo}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Currículo
            </a>
          </li>
        </ul>
      </div>
      <div className="w-full h-full flex flex-col">
        <h3 className="text-xl uppercase text-designColor tracking-wide">
          desenvolvedores
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <NavLink
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              to="/documentacao"
            >
              Documentação
            </NavLink>
          </li>
          <li>
            <NavLink
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              to="/ferramentas"
            >
              Ferramentas
            </NavLink>
          </li>
          <li>
            <NavLink
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              to="/tecnologias"
            >
              Tecnologias
            </NavLink>
          </li>
          <li>
            <NavLink
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              to="/services"
            >
              Serviços utilizados
            </NavLink>
          </li>
          <li>
            <a
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              href="https://github.com/pablo-santos21"
              target="_blank"
              rel="noopener noreferrer"
            >
              Código aberto
            </a>
          </li>
        </ul>
      </div>
      <div className="w-full h-full flex flex-col">
        <h3 className="text-xl uppercase text-designColor tracking-wide">
          Termos
        </h3>
        <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
          <li>
            <NavLink
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              to="/termo-de-seguranca"
            >
              Termo de segurança
            </NavLink>
          </li>
          <li>
            <NavLink
              className="w-full text-lg relative hover:text-designColor hover:underline duration-300 cursor-pointer"
              to="/termo-de-servico"
            >
              Termo de Serviço
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterInterno;
