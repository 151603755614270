import { React, useEffect } from 'react';
import Titulo from '../layout/Titulo';
import NavbarInterno from '../navbar/NavbarInterno';
import FooterInterno from '../footer/FooterInterno';
import Copy from '../footer/Copy';

import ReactGA from 'react-ga';

const TermoSeguranca = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <NavbarInterno />
      <section
        id="termoSeguranca"
        className="w-full h-full py-10 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Titulo title="Termos de Segurança" />
        </div>

        <div className="flex flex-col gap-4 pb-8 items-center">
          <p>
            Bem-vindo à página de termo de segurança do pablosantos.dev! Nós
            levamos a privacidade e a segurança das informações de nossos
            usuários muito a sério, e é por isso que implementamos medidas
            rigorosas para garantir que seus dados estejam sempre protegidos.
          </p>

          <p>
            De acordo com a Lei Geral de Proteção de Dados (LGPD), o Programador
            é responsável pelo tratamento adequado dos dados pessoais de seus
            usuários. Essa legislação garante a proteção dos direitos
            fundamentais de privacidade e de proteção de dados pessoais.
            Portanto, comprometemo-nos a seguir rigorosamente as exigências da
            LGPD em todos os nossos processos.
          </p>

          <p>
            Nós coletamos informações pessoais apenas quando necessário para a
            prestação de nossos serviços. Essas informações podem incluir nome,
            endereço de e-mail, endereço de IP e outras informações que você
            escolher compartilhar conosco. Esses dados são armazenados em
            servidores seguros e somente são acessíveis por membros autorizados
            da nossa equipe.
          </p>

          <p>
            Nosso site também pode coletar informações por meio de cookies e
            outras tecnologias de rastreamento. Isso nos ajuda a entender melhor
            como nossos usuários interagem com nosso site, permitindo-nos
            melhorar a experiência do usuário e oferecer serviços
            personalizados. No entanto, respeitamos a privacidade de nossos
            usuários e não compartilhamos essas informações com terceiros.
          </p>

          <p>
            Em caso de necessidade de compartilhamento de dados pessoais com
            terceiros, garantimos que esses parceiros estejam em conformidade
            com as normas de proteção de dados, de acordo com a LGPD. Além
            disso, somente compartilhamos informações pessoais com esses
            parceiros quando estritamente necessário para a prestação dos
            serviços.
          </p>

          <p>
            Caso ocorra algum incidente de segurança em nosso site, tomaremos as
            medidas necessárias para mitigar o impacto e notificaremos as
            autoridades competentes e os usuários afetados, conforme exigido
            pela LGPD. Continuaremos aprimorando nossos sistemas de segurança e
            privacidade para garantir a proteção de seus dados pessoais.
          </p>

          <p>
            Ao utilizar nosso site, você concorda com nossos termos de segurança
            e com a coleta e tratamento de seus dados pessoais de acordo com a
            LGPD. Se você tiver alguma dúvida ou preocupação sobre nossa
            política de privacidade, entre em contato conosco para que possamos
            ajudá-lo.
          </p>
        </div>
      </section>
      <FooterInterno />
      <Copy />
    </div>
  );
};

export default TermoSeguranca;
