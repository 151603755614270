import { React, useEffect } from 'react';
import Titulo from '../layout/Titulo';
import NavbarInterno from '../navbar/NavbarInterno';
import FooterInterno from '../footer/FooterInterno';
import Copy from '../footer/Copy';

import ReactGA from 'react-ga';

const Ferramentas = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <NavbarInterno />
      <section
        id="ferramentas"
        className="w-full h-[90vh] py-10 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Titulo title="Saiba o que usei" desc="Ferramentas" />
        </div>

        <div className="flex flex-col gap-6 xl:gap-14 pb-8 items-center">
          <ul className="text-2xl lg:text-lg list-disc flex flex-col gap-2">
            <li className="text-white font-bold hover:text-designColor">
              VS code
            </li>
            <li className="ml-6 hover:text-designColor">Dracula</li>
            <li className="ml-6 hover:text-designColor">ESLint</li>
            <li className="ml-6 hover:text-designColor">ES7+ React</li>
            <li className="ml-6 hover:text-designColor">Live Server</li>
            <li className="ml-6 hover:text-designColor">Material Icon</li>
            <li className="ml-6 hover:text-designColor">Prettier</li>

            <li className="text-white font-bold hover:text-designColor">
              Google Chrome
            </li>
            <li className="text-white font-bold hover:text-designColor">
              Blisk
            </li>
            <li className="text-white font-bold hover:text-designColor">
              Trello
            </li>
            <li className="text-white font-bold hover:text-designColor">
              Photoshop
            </li>
            <li className="text-white font-bold hover:text-designColor">
              Sourcetree
            </li>
          </ul>
        </div>
      </section>
      <FooterInterno />
      <Copy />
    </div>
  );
};

export default Ferramentas;
