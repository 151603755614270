import React from 'react';
import Titulo from '../layout/Titulo';
import contactImg from '../../assets/images/contact/contactImg.png';
import CardContato from './CardContato';
import ContatoRight from './ContatoRight';

const Contato = () => {
  return (
    <section
      id="contato"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Titulo title="Entre em contato comigo" desc="Contato" />
      </div>

      <div className="w-full">
        <div className="w-full flex flex-col lg:flex-row justify-between">
          <CardContato
            src={contactImg}
            alt="Contato"
            title="Pablo Santo"
            cargo="Desenvolvedor Jr"
            desc="Desenvolvedor Frontend Jr, utilizando, principalmente o React e Tailwind para realização de projetos, além de ter conhecimento de C#, API REST, SQL, etc."
            cel="+55 21 97013-9730"
            email="contato@pablosantos.dev"
          />

          <ContatoRight />
        </div>
      </div>
    </section>
  );
};

export default Contato;
