export const navLinksFooterData = [
  {
    _id: 1001,
    title: 'Sobre',
    link: 'home',
  },
  {
    _id: 1002,
    title: 'Serviços',
    link: 'servicos',
  },
  {
    _id: 1006,
    title: 'Contato',
    link: 'contato',
  },
];
