import React from 'react';
import { RiStarFill } from 'react-icons/ri';
import quote from '../../assets/images/testmonial/quote.png';

const Slide = ({ src, alt, empresa, resp, cargo, service, subTitle, desc }) => {
  return (
    <div className="w-full">
      <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
        <div className="w-full lg:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-8 rounded-lg shadow-shadowOne flex flex-col md:flex-row lg:flex-col gap-8 justify-center md:justify-start lg:justify-center">
          <img
            className="h-72 md:h-32 lg:h-64 w-full rounded-lg object-cover"
            src={src}
            alt={alt}
          />

          <div className="w-full flex flex-col justify-end">
            <p className="text-xs uppercase text-designColor tracking-wide mb-2">
              {empresa}
            </p>
            <h3 className="text-2xl font-bold">{resp}</h3>
            <p className="text-base -tracking-wide text-gray-500">{cargo}</p>
          </div>
        </div>
        <div className="w-full lg:w-[60%] h-full flex flex-col justify-between">
          <img className="w-20 lg:w-32" src={quote} alt="" />
          <div className="w-full h-[70%] py-10 bg-gradient-to-r from-[#1e2024] to-[#23272b] rounded-lg shadow-shadowOne p-4 lg:p-8 flex flex-col justify-center gap-4 lg:gap-8">
            <div className="flex flex-rox justify-between lg:items-center py-6 border-b-2 border-b-gray-900">
              <div className="">
                <h3 className="text-xl lg:text-2xl font-medium tracking-wide">
                  {service}
                </h3>
                <p className="text-base text-gray-400 mt-3">{subTitle}</p>
              </div>
              <div className="text-yellow-500 flex gap-1">
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
              </div>
            </div>
            <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">
              {desc}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
