import React from 'react';
import { BsGithub } from 'react-icons/bs';
import { FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';

const MeEncontre = () => {
  return (
    <div className="flex gap-4">
      <span className="bannerIcon">
        <a
          className="p-6"
          href="https://wa.me/5521970139730"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp />
        </a>
      </span>
      <span className="bannerIcon">
        <a
          className="p-6"
          href="https://www.linkedin.com/in/pablo-engenhariadesoftware/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn />
        </a>
      </span>
      <span className="bannerIcon">
        <a
          className="p-6"
          href="https://github.com/pablo-santos21"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsGithub />
        </a>
      </span>
    </div>
  );
};

export default MeEncontre;
