export const navLinksdata = [
  {
    _id: 1001,
    title: 'Home',
    link: 'home',
  },
  {
    _id: 1002,
    title: 'Serviços',
    link: 'servicos',
  },
  {
    _id: 1003,
    title: 'Currículo',
    link: 'curriculo',
  },
  {
    _id: 1004,
    title: 'Projetos',
    link: 'projetos',
  },
  {
    _id: 1005,
    title: 'Testemunhos',
    link: 'testemunho',
  },
  {
    _id: 1006,
    title: 'Contato',
    link: 'contato',
  },
];
