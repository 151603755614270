import React from 'react';
import logo from '../../assets/images/website/logo.png';
import NavbarInterno from '../navbar/NavbarInterno';

const Page404 = () => {
  return (
    <div>
      <NavbarInterno />
      <div className="w-full h-[90vh] items-center">
        <div className="flex flex-col text-center items-center justify-center py-20">
          <div className="flex items-center text-center justify-center gap-8">
            <img src={logo} alt="Logo" className="w-[80%]" />
          </div>
          <h1 className="text-4xl font-bold text-designColor">Página 404</h1>
        </div>
      </div>
    </div>
  );
};

export default Page404;
