import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import Portifolio from './components/Pages/Portifolio';
import Ferramentas from './components/Pages/Ferramentas';
import Services from './components/Pages/Services';
import Tecnologias from './components/Pages/Tecnologias';
import Documentacao from './components/Pages/Documentacao';
import TermoSeguranca from './components/Pages/TermoSeguranca';
import TermoServico from './components/Pages/TermoServico';
import Page404 from './components/Pages/Page404';
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-7Z8VH2JD96';
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="w-full h-auto bg-bodyColorDark text-lightText px-4">
      <div className="max-w-screen-xl mx-auto">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="portifolio" element={<Portifolio />} />
            <Route path="ferramentas" element={<Ferramentas />} />
            <Route path="services" element={<Services />} />
            <Route path="tecnologias" element={<Tecnologias />} />
            <Route path="documentacao" element={<Documentacao />} />
            <Route path="termo-de-seguranca" element={<TermoSeguranca />} />
            <Route path="termo-de-servico" element={<TermoServico />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
