import React from 'react';
import { motion } from 'framer-motion';
import CardResumo from './CardResumo';

const Experiencia = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.8 } }}
      className="w-full flex flex-col md:flex-row gap-20"
    >
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            Experiências
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Desenvolvedor</h2>
        </div>
        <div className="w-full mt-14 h-[1000px] border-l-[6px] border-black border-opacity-30 flex flex-col gap-10">
          <CardResumo
            title="Desenvolvedor FullStack"
            subTitle="Pablo Santos (2021 - atualmente)"
            desc="Realizar criação e manutenção de sites para clientes contratantes, utilizando como linguagens os HTML, React, Node Js, MySQL, etc."
          />
          <CardResumo
            title="Desenvolvedor FullStack"
            subTitle="RG26 COMUNICAÇÃO LTDA (2020 - 2021)"
            desc="Realizar criação e manutenção de sites para clientes contratantes, utilizando como linguagens os HTML, JS, PHP e etc."
          />
          <CardResumo
            title="Desenvolvimento FrontEnd"
            subTitle="Project LAB (2019 - 2020)"
            desc="Realizar criação e manutenção dos sites da empresa, utilizando como ferramentas o Wordpress e Loja Integrada e como linguagens, o HTML, JS, PHP e etc."
          />
        </div>
      </div>

      {/* Parte 2 */}
      <div>
        <div className="py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            Experiências
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Suporte</h2>
        </div>
        <div className="w-full mt-14 h-[1000px] border-l-[6px] border-black border-opacity-30 flex flex-col gap-10">
          <CardResumo
            title="Técnico de informática"
            subTitle="Club de Regatas Vasco da Gama (2019 - 2019)"
            desc="Realizar manutenção de computadores e rede do estádio São Januário e de todas as sedes do clube, realizar o gerenciamento de chamados e pesquisas de tecnologias para o TI."
          />
          <CardResumo
            title="Auxiliar técnico de suporte"
            subTitle="Solution Informatica (2016 - 2016)"
            desc="Realizar manutenção de computadores e câmeras de todos os clientes contratantes, além de realizar o gerenciamento de chamados."
          />
          <CardResumo
            title="técnico de informática"
            subTitle="RV note (2014 - 2016)"
            desc="Realizar manutenção de computadores e notebooks de clientes que compareciamc à loja, além de gerenciar as ordens de serviço geradas."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experiencia;
