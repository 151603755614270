import React from 'react';

const Copy = () => {
  return (
    <div className="w-full py-10">
      <p className="text-center text-gray-500 text-base">
        “I know not all that may be coming, but be it what it will, I'll go to
        it laughing.”
      </p>
      <p className="text-center text-gray-500 text-base">
        © 2023. Todos os Direitos Reservados por Pablo Santos.
      </p>
    </div>
  );
};

export default Copy;
