import React from 'react';
import logo from '../../assets/images/website/logo.png';
import { NavLink } from 'react-router-dom';

const NavbarInterno = () => {
  return (
    <div className="w-full h-24 sticky top-0 z-20 bg-bodyColorDark flex justify-between items-center font-titleFont border-b-[1px] border-b-gray-600">
      <div>
        <img src={logo} alt="Logo" width={70} height={50} />
      </div>
      <div>
        <ul className="inline-flex items-center gap-6 lg:gap-10 ">
          <li className="text-base font-normal text-gray-300 tracking-wide cursor-pointer hover:text-designColor duration-300">
            <NavLink to="/">Home</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarInterno;
