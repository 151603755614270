import React from 'react';

const Botao = ({ ender, title }) => {
  return (
    <div className="py-20 items-center justify-center text-center">
      <a
        className="text-md bg-designColor text-darkText font-bold px-6 py-4 hover:bg-[#04cf00] cursor-pointer rounded-full uppercase"
        href={ender}
      >
        {title}
      </a>
    </div>
  );
};

export default Botao;
