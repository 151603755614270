import { React, useEffect } from 'react';
import Titulo from '../layout/Titulo';
import NavbarInterno from '../navbar/NavbarInterno';
import FooterInterno from '../footer/FooterInterno';
import Copy from '../footer/Copy';

import ReactGA from 'react-ga';

const TermoServico = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <NavbarInterno />
      <section
        id="termoServico"
        className="w-full h-full py-10 border-b-[1px] border-b-black"
      >
        <div className="flex justify-center items-center text-center">
          <Titulo title="Termos de Serviço" />
        </div>

        <div className="flex flex-col gap-4 pb-8 items-center">
          <p>
            Bem-vindo à página de termo de serviço do site do programador! Aqui,
            explicaremos nossas políticas e condições de uso de nossos serviços,
            para que você possa ter uma compreensão clara do que pode esperar ao
            utilizar nosso site.
          </p>

          <p>
            Ao utilizar nosso site, você concorda com nossos termos de serviço.
            Isso inclui a aceitação de que somos uma plataforma de serviços de
            programação e que podemos atualizar, alterar ou modificar nossos
            serviços a qualquer momento, sem aviso prévio.
          </p>

          <p>
            Além disso, você concorda em não utilizar nossos serviços para fins
            ilegais ou que possam violar os direitos de terceiros. Isso inclui o
            envio de conteúdo ofensivo, difamatório ou enganoso, bem como o
            envio de spam ou de qualquer forma de propaganda não autorizada.
          </p>

          <p>
            Nosso site pode conter links para sites de terceiros. Esses sites
            são de responsabilidade exclusiva de seus proprietários e não temos
            controle sobre eles. Portanto, não podemos garantir a precisão ou a
            qualidade do conteúdo desses sites. É sua responsabilidade verificar
            os termos de serviço desses sites antes de utilizá-los.
          </p>

          <p>
            Nosso site pode oferecer serviços pagos. Ao optar por um serviço
            pago, você concorda em pagar todas as taxas associadas ao serviço.
            Todas as taxas são estabelecidas em nossa página de pagamento e
            podem ser alteradas a qualquer momento. Reservamo-nos o direito de
            cancelar ou suspender sua conta caso haja atrasos ou falha no
            pagamento de suas taxas.
          </p>

          <p>
            Nós nos comprometemos a fornecer serviços de alta qualidade e a
            manter a segurança de nossos usuários. No entanto, não podemos
            garantir a disponibilidade contínua de nossos serviços e não nos
            responsabilizamos por quaisquer danos ou perdas decorrentes da
            interrupção de nossos serviços.
          </p>

          <p>
            Ao utilizar nossos serviços, você concorda em cumprir todas as leis
            e regulamentos aplicáveis. Caso viole esses termos de serviço,
            podemos suspender ou cancelar sua conta a qualquer momento, sem
            aviso prévio.
          </p>

          <p>
            Se você tiver alguma dúvida ou preocupação sobre nossos termos de
            serviço, entre em contato conosco para que possamos ajudá-lo.
          </p>
        </div>
      </section>
      <FooterInterno />
      <Copy />
    </div>
  );
};

export default TermoServico;
