import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { FiMenu } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import logo from '../../assets/images/website/logo.png';
import { navLinksdata } from '../../constants/index';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="w-full h-24 sticky top-0 z-20 bg-bodyColorDark flex justify-between items-center font-titleFont border-b-[1px] border-b-gray-600">
      <div>
        <img src={logo} alt="Logo" width={70} height={50} />
      </div>
      <div>
        <ul className="hidden mdl:inline-flex items-center gap-6 lg:gap-10 ">
          {navLinksdata.map(({ _id, title, link }) => (
            <li
              key={_id}
              className="text-base font-normal text-gray-300 tracking-wide cursor-pointer hover:text-designColor duration-300"
            >
              <Link
                activeClass="active"
                to={link}
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
        <span
          onClick={() => {
            setShowMenu(!showMenu);
          }}
          className="text-xl mdl:hidden bg-black w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor cursor-pointer"
        >
          <FiMenu />
        </span>
        {showMenu && (
          <div className="w-[80%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-700 p-4 scrollbar-hide">
            <div className="flex flex-col gap-8 py-4 px-2 relative">
              <ul className="flex flex-col gap-4">
                {navLinksdata.map(({ _id, title, link }) => (
                  <li
                    key={_id}
                    className="text-base font-normal text-gray-300 tracking-wide cursor-pointer hover:text-designColor duration-300"
                  >
                    <Link
                      onClick={() => {
                        setShowMenu(false);
                      }}
                      activeClass="active"
                      to={link}
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={500}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
              <span
                onClick={() => {
                  setShowMenu(false);
                }}
                className="top-4 absolute right-4 text-gray-400 hover:text-designColor duration-300 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
